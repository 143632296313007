import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout-header-footer/layout.js";
import SEO from "../components/seo.js";
import Img from "gatsby-image";
import "./blog-post.css";

export default function BlogPost({ data }) {
  let post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  const timeToRead = (postData) => {
    if (postData.timeToRead > 1){
      return <p>Read time: {postData.timeToRead} minutes</p>
    } if (postData.timeToRead === 1) {
      return <p>Read time: {postData.timeToRead} minute</p>
    } else {
      return <p>Read time: under a minute</p>
    }
  }

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <article className="blog-post">
        <Link to="/blogs">&#171; Back to blog list</Link>
        <Img fluid={featuredImgFluid} />
        <h1>{post.frontmatter.title}</h1>
        <section className="blog-post-info">
          <p>Posted {post.frontmatter.date}</p>
          {timeToRead(post)}
        </section>
        <section className="post-body" dangerouslySetInnerHTML={{ __html: post.html }} />
        <Link to="/blogs">&#171; Back to blog list</Link>
      </article>
    </Layout>
  )
}
export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`